$ = require 'jquery'

class MultiDrop
  constructor: (@$input, @$select, options = {}) ->
    { @search, @dropClass, @$list, @closeHtml = false, @closeSelector = 'li' } = options

    this.$drop = this.$input.parent().find('.' + @dropClass)

    this.$drop.on 'click', 'li', (e) =>
      selected = $(e.target)
      @choose(selected)
      this.$drop.hide()

    this.$input.keydown (e) =>
      if e.keyCode == 13
        e.preventDefault()
        selected = this.$drop.children().first()
        @choose(selected)
        this.$input.val('')

    this.$input.keyup (e) =>
      console.log e.keyCode
      this.$drop.hide()
      if this.$input.val() == ''
        return
      if e.keyCode == 13
        return
      @search(this.$input.val(), @fill)

    if this.$list
      this.$list.on 'click', @closeSelector, (e) =>
        id = $(e.target).closest('li').data('id')
        @unchoose(id)

    $(document).click (e) =>
      if !this.$drop.is(e.target) && this.$drop.has(e.target).length == 0
        this.$drop.hide()
        this.$input.val('')

  choose: ($selected) =>
    id = $selected.data('id')
    selector = 'option[value="' + id + '"]'
    if this.$select.find(selector).length == 0
      $option = $ '<option/>',
        value: id
        selected: 'selected'
      $option.text($selected.text())
      this.$select.append($option)
      if this.$list
        $el = $selected.clone(true)
        if @closeHtml
          $close = $(@closeHtml)
          $el.append $close
        this.$list.append($el)

      this.$select.trigger('change')

  unchoose: (id) =>
    selector = 'option[value="' + id + '"]'
    this.$select.find(selector).remove()
    if this.$list
      this.$list.find('li').each ->
        if $(this).data('id') == id
          $(this).remove()

  fill: (data) =>
    this.$drop.empty()
    if data.length == 0
      this.$drop.hide()
    else
      sorted = []
      for id, item of data
        sorted.push([id, item])
      sorted.sort (a, b) ->
        return a[1].localeCompare(b[1])

      for i in sorted
        id = i[0]
        item = i[1]
        $li = $ '<li/>'
        $li.data('id', id)
        $li.text(item)
        this.$drop.append($li)
  #      $option = $ '<option/>',
  #        value: id
  #      $option.text(item)
  #      this.$select.append($option)
      this.$drop.show()

module.exports = MultiDrop