class Checkbox
  constructor: ($input, $label, opts = {}) ->
    {@label_class = 'checkbox-label', @checked_class = 'checked', trigger_click = true} = opts
    this.$input = $input
    this.$label = $label
    this.$label.addClass(@label_class)

    this.$input.change @onChange

    if trigger_click
      this.$label.click =>
        this.$input.click()
    @updateClass()

  updateClass: =>
    checked = this.$input.prop('checked')
    this.$label.toggleClass(@checked_class, checked)

  onChange: =>
    @updateClass()

module.exports = Checkbox