$ = require 'jquery'
_ = require 'underscore'

noResponsiveness = require("./responsive").noResponsiveness
router = require './router'
#template = require './template'

images_url = '//www.tourtrans.ru/js/online-styles/new/'
catalog_url = window.location.href.split("#")[0]

$container = $('.ttv-catalog')
page = 0
sort = ''

if !$container.length
  return

console.log 'Найден элемент виджета каталога. Инициализация виджета...'

results_tpl = _.template(require './search_results.html')
$container.append(results_tpl())

getExcursions = (id, cb) ->
  request_url = '//www.tourtrans.ru/modules/getExcursionsByType?tour_id=' + id + '&jsoncallback=?'
  $.getJSON request_url, (data) ->
    cb(data)

getAirPrices = (id, cb) ->
  request_url = '//www.tourtrans.ru/modules/getAirPrices?tour_id=' + id + '&jsoncallback=?'
  $.getJSON request_url, (data) ->
    cb(data)

getRailwayPrices = (id, cb) ->
  request_url = '//www.tourtrans.ru/modules/getRailwayPrices?tour_id=' + id + '&jsoncallback=?'
  $.getJSON request_url, (data) ->
    cb(data)

getParomPrices = (id, cb) ->
  request_url = '//www.tourtrans.ru/modules/getParomPrices?tour_id=' + id + '&jsoncallback=?'
  $.getJSON request_url, (data) ->
    cb(data)

getVisaPrices = (country_id, tour_id, cons_id, cb) ->
  request_url = '//www.tourtrans.ru/modules/getVisaPrices?country_id=' + country_id + '&tour_id=' + tour_id + '&cons_id=' + cons_id + '&jsoncallback=?'
  $.getJSON request_url, (data) ->
    cb(data)

showPopup = (title, html) ->
  $popup = $ '<div/>',
    class: 'ttv-popup'
  if title
    $h3 = $('<h3>' + title + '</h3>')
    $popup.append $h3
  $popup.append(html)
  $container.append($popup)

showTour = (id) ->
  request_url = '//www.tourtrans.ru/modules/getTour/' + id + '?jsoncallback=?'
  $.getJSON request_url, (data) ->
    console.log 'tour', data
    tour_tpl = _.template(require './tour.html')
    data.images_url = '//www.tourtrans.ru/js/online-styles/new/'
    $container.empty().append(tour_tpl(data))
    $('.ttv-request-block .ttv-submit').toggleClass('ttv-disabled', !this.checked)
    window.scrollTo(0, 0)

loadPage = ->
  console.log 'loadPage'
  variables = router.variables()
  variables.page = page
  if sort != ''
    variables.sort = sort
  params = $.param(variables)

  console.log 'page=', page, 'sort=', sort
  request_url = '//www.tourtrans.ru/modules/searchTours.html?' + params + '&jsoncallback=?'
  console.log 'request_url', request_url
  $.getJSON request_url, (data) ->
    console.log 'Результаты поиска', data
    $results = $('.ttv-search-results')
    if $results.length == 0
      $container.empty().append(results_tpl())
      $results = $('.ttv-search-results')
    tour_item_tpl = _.template(require './tour_item.html')
    for tour in data.results
#        console.log tour
      tour.del = router.del
      tour.catalog_url = catalog_url
      $results.append(tour_item_tpl(tour))
    $('.ttv-results-count').text(data.results_count)
    if data.more
      initPageLoad()
  page += 1


initPageLoad = ->
  console.log 'initPageLoad'
  $(window).on 'scroll.page', ->
    if ($(window).scrollTop() > $container.height() - $(window).height() - 200)
      $(window).off 'scroll.page'
      loadPage()

navigate = ->
  console.log 'Навигация'
  $('.ttv-search-results').empty()

  if router.path() == 'search'
    page = 1
    console.log 'Поиск по турам'
    loadPage()
  if router.path() == 'tour'
    $(window).off 'scroll.page'
    showTour(router.variables().id)

resize = ->
  noResponsiveness($container)

$(window).on 'hashchange', ->
  console.log 'hash changed'
  navigate()

$(window).resize ->
  resize()

$container.on 'click', '.ttv-popup-link', (e) ->
  e.preventDefault()
  url = e.target.href
  title = e.target.title
  if /viza/.test(url)
    country_id = url.match /(\d+)\.html/
    country_id = country_id[1]
    cons_id = url.match /cons_id=(\d+)/
    cons_id = if cons_id then cons_id[1] else ''
    tour_id = url.match /tour_id=(\d+)/
    tour_id = if tour_id then tour_id[1] else ''

    console.log 'viza', country_id, cons_id, tour_id
    getVisaPrices country_id, tour_id, cons_id, (data) ->
      tpl = _.template(require './viza_docs.html')
      showPopup(title, tpl(data))

  if /airPrice/.test(url)
    id = url.match /\d+/
    id = id[0]

    console.log 'air', id
    getAirPrices id, (data) ->
      tpl = _.template(require './air_prices.html')
      showPopup(title, tpl(items: data))

  if /railwayPrice/.test(url)
    id = url.match /\d+/
    id = id[0]

    console.log 'railway', id
    getRailwayPrices id, (data) ->
      tpl = _.template(require './railway_prices.html')
      showPopup(title, tpl(items: data))

  if /paromPrice/.test(url)
    id = url.match /\d+/
    id = id[0]

    console.log 'parom', id
    getParomPrices id, (data) ->
      tpl = _.template(require './parom_prices.html')
      showPopup(title, tpl(table: data))

$container.on 'click', '.ttv-excur-link', (e) ->
  e.preventDefault()
  $link = $(this)
  $item = $link.closest('.ttv-tour-item')
  id = $item.data('tour-id')
  getExcursions id, (data) ->
    $popup = $link.parent().find('.ttv-excur-popup')
    ex_tpl = _.template(require './excur.html')
    if $popup.length
      $popup.replaceWith(ex_tpl({ types: data }))
    else
      $link.parent().append(ex_tpl({ types: data }))

$container.on 'mouseover click', '.ttv-icons li img', (e) ->
  $container.find('.ttv-icons li').removeClass('active')
  $item = $(this).closest('li')
  $item.addClass('active')
  descr = $item.find('div').text()
  $container.find('.ttv-icons .icon-descr').text(descr)
#  $container.find('.ttv-icons li div').hide()
#  $item.find('div').show()

$container.on 'click', '.sort_duration', (e) ->
  e.preventDefault()
  $results = $('.ttv-search-results')
  $results.empty()
  page = 0
  if sort == 'duration'
    sort = 'duration desc'
  else
    sort = 'duration'
  $('.sort_duration').addClass('active')
  $('.sort_price').removeClass('active')
  loadPage()


$container.on 'click', '.sort_price', (e) ->
  e.preventDefault()
  $results = $('.ttv-search-results')
  $results.empty()
  page = 0
  if sort == 'minprice'
    sort = 'minprice desc'
  else
    sort = 'minprice'
  $('.sort_price').addClass('active')
  $('.sort_duration').removeClass('active')
  loadPage()

window.toggleDisabled = false
toggle = (e) ->
  e.preventDefault()

  if window.toggleDisabled
    return

  console.log('toggle')
  window.toggleDisabled = true
  setTimeout ->
    window.toggleDisabled = false
  , 100
  $collapsible = $(this).closest('.ttv-collapsible')
  $collapsible.toggleClass('ttv-collapsed')
  if $collapsible.hasClass('ttv-collapsed')
    $collapsible.find('.ttv-toggle img').attr('src', images_url + 'arrow_expand.svg')
  else
    $collapsible.find('.ttv-toggle img').attr('src', images_url + 'arrow_collapse.svg')

$(document).on 'click', '.ttv-collapsible .ttv-toggle', toggle

$(document).on 'click', '.ttv-date', (e) ->
  e.preventDefault()
  if $(this).hasClass('ttv-red')
    return
  $el = $(this)
  date_id = parseInt($(this).data('date-id'))
  params = $.param({ date_id: date_id})
  request_url = '//www.tourtrans.ru/modules/getPlaces.html?' + params + '&jsoncallback=?'
  $.getJSON request_url, (data) ->
    bus_tpl = _.template(require './bus.html')
    $('.ttv-bus').remove()

    $tr = $el.closest('tr')
    data.images_url = '//www.tourtrans.ru/js/online-styles/new/'
    $tr.after(bus_tpl(data))

$(document).on 'click', '.ttv-bus .ttv-close', (e) ->
  e.preventDefault()
  $(this).closest('.ttv-bus').remove()

$(document).on 'change', '#ttv-pd', (e) ->
  $('.ttv-request-block .ttv-submit').toggleClass('ttv-disabled', !this.checked)

$(document).on 'click', '.ttv-submit', (e) ->
  e.preventDefault()

  email = $(this).closest('.ttv-catalog').data('email')
  if !email
    alert('Виджет каталога настроен неправильно')

  $form = $(this).closest('form')
  $form.find('.ttv-field').removeClass('error')
  data = $form.serializeArray()
  result = {}
  console.log data
  $.map data, (n, i) ->
    console.log n['name'], n['value']
    result[n['name']] = n['value']
  data = result
  data.agency_email = email
  data.url = window.location.href
  console.log data

  validated = true
  for name, value of data
    if value.trim() == '' and name != 'message'
      validated = false
      $form.find('input[name=' + name + ']').parent().addClass('error')

  if validated
    params = $.param(data)
    request_url = '//www.tourtrans.ru/modules/request.html?' + params + '&jsoncallback=?'
    $.getJSON request_url, (data) ->
      if data.result
        alert('Ваша заявка отправлена!')
  else
    alert('Заполните все обязательные поля!')

$(document).on 'click', '.ttv-show-request', (e) ->
  e.preventDefault()
  $('.ttv-request-block').show()
  $('.ttv-request-block')[0].scrollIntoView()

$(document).on 'click', '.ttv-close', (e) ->
  e.preventDefault()
  $(this).closest('.ttv-closable').hide()

$(document).on 'click', (e) ->
  $modals = $('.ttv-excur-popup')
  $modals.each (i, el) ->
    if $(el).has(e.target).length == 0
      $(el).hide()
  $popups = $('.ttv-popup')
  $popups.each (i, el) ->
    if $(el).has(e.target).length == 0
      $(el).remove()


resize()
navigate()

