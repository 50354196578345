qs = require 'query-string'
del = '*'

#hash = window.location.hash
#pos = hash.indexOf('?')
#query = hash.substr(pos)
##variables = qs.parse(window.location.search)
#variables = qs.parse(query)
#path = hash.substr(0, pos)
#
#if path[0] == '#'
#  path = path.substr(1, path.length-1)
#
#console.log 'variables', variables
#console.log 'path', path

getVariables = ->
  console.log window.location.hash
  hash = window.location.hash
  pos = hash.indexOf(del)
  query = hash.substr(pos+1)
  variables = qs.parse(query)
  console.log query, variables

  return variables

getPath = ->
  hash = window.location.hash
  pos = hash.indexOf(del)
  query = hash.substr(pos+1)
  path = hash.substr(0, pos)

  if path[0] == '#'
    path = path.substr(1, path.length-1)

  return path


module.exports = {
  del: del,
  path: getPath,
  variables: getVariables,
}