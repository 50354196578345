noResponsiveness = require("./responsive").noResponsiveness
variables = require('./router').variables

$ = require 'jquery'
_ = require 'underscore'

datepickerFactory = require 'jquery-datepicker'
datepickerRUFactory = require 'jquery-datepicker/i18n/jquery.ui.datepicker-ru'

datepickerFactory($)
datepickerRUFactory($)
$.datepicker.regional['ru']

Checkbox = require './../components/checkbox'

images_url = '//www.tourtrans.ru/js/online-styles/new/'

$container = $('.ttv-search')

if !$container.length
  return

console.log 'Найден элемен виджета поиска. Инициализация виджета...'

#template = require './template'
search_tpl = _.template(require './search_form.html')
#search_tpl = template(require './search_form.html')
$container.append(search_tpl())


$('#ttv-out-from').datepicker
  minDate: 0
  beforeShow: (input, inst) ->
    $(inst.dpDiv).addClass('ttv-datepicker')
$('#ttv-out-to').datepicker
  minDate: 0
  beforeShow: (input, inst) ->
    $(inst.dpDiv).addClass('ttv-datepicker')


getFormData = ($form) ->
  data = $form.serializeArray()
  result = {}
  $.map data, (n) ->
    result[n['name']] = n['value']
  return result

validateForm = (data) ->
  data.max_price = data.max_price.replace(/\s/g, '')
  return data

refreshResultsCount = ->
  console.log 'Обновляю кол-во результатов'

  data = getFormData($form)
  data = validateForm(data)

  params = $.param(data)

#  params = $form.serialize()
  console.log 'Параметры поиска(отправка)', params
  window.results_count_ts = new Date().getTime()
  $.getJSON '//www.tourtrans.ru/modules/searchResultsCount.html?' + params + '&timestamp=' + window.results_count_ts + '&jsoncallback=?', (data) ->
    console.log 'results count', data
    if data.timestamp >= window.results_count_ts
      $('.ttv-results-count').text(data.results_count)

$container.find('input[type=checkbox]').each (i, e) ->
  $el = $(e)
  $label = $el.closest('label')
  new Checkbox($el, $label, {trigger_click: false, label_class: 'ttv-checkbox-label'})

$container.find('input[type=checkbox]').change ->
  refreshResultsCount()

$container.find('input[type=text]').change ->
  refreshResultsCount()

$container.find('input[type=text]').keyup ->
  if this.id != 'ttv-cities'
    refreshResultsCount()

$('select[name=cities]').change ->
  refreshResultsCount()

$('.ttv-clear').click (e) ->
  e.preventDefault()
  $container.find('input[type=text]').val('')
  $container.find('input[type=checkbox]').prop('checked', false).trigger('change')

#$('#ttv-city').keyup (e) ->
#  if $(this).val() == ''
#    # close popup
#    return;
#
#  $.getJSON '//www.tourtrans.ru/modules/searchCitiesByName.html?q=' + $(this).val() + '&jsoncallback=?', (data) ->
#    console.log 'q data', data

MultiDrop = require './multidrop'

cities_md = new MultiDrop($('#ttv-cities'), $('.ttv-search select[name=cities]'), {
  dropClass: 'ttv-cities-list'
  $list: $('.ttv-selected-cities')
  closeHtml: '<img src="' + images_url + 'close_white.svg">'
  closeSelector: 'img'
  search: (val, cb) ->
    window.cities_search_ts = new Date().getTime()
    $.getJSON '//www.tourtrans.ru/modules/searchCitiesByName.html?q=' + val + '&timestamp=' + window.cities_search_ts + '&jsoncallback=?', (data) ->
#      console.log 'ts=', data.timestamp, 'w.ts=', window.cities_search_ts
      if data.timestamp >= window.cities_search_ts
#        console.log 'q data', data
        cb(data.cities)
})

$.getJSON '//www.tourtrans.ru/modules/searchDepartureCitiesList.html?jsoncallback=?', (data) ->
  for id, city of data
    option = $ '<option/>',
      value: id
    $('#ttv-city-out').append(option.text(city))

  $('#ttv-city-out').change ->
    refreshResultsCount()
    city_id = $(this).val()
    loadCountriesList(city_id)

initInputValues = ->
  for k, v of variables()
    if v
      $el = $('[name="' + k + '"]')
      type = $el.attr('type') or $el.prop('tagName').toLowerCase()
      switch type
        when 'checkbox'
          $el.prop('checked', true).trigger('change')
        when 'text'
          $el.val(v)
        when 'select'
          $el.val(v)
        else
          console.error(k, 'is unknown')

loadCountriesList = (city_id) ->
  if city_id
    city = 'city_id=' + city_id + '&'
  $.getJSON '//www.tourtrans.ru/modules/searchCountriesList.html?' + city + 'jsoncallback=?', (data) ->
    $('#ttv-country').empty()
      .append('<option value="">Выберите страну</option>')
    sorted = []
    for id, country of data
      sorted.push([id, country])
    sorted.sort (a, b) ->
      return a[1].localeCompare(b[1])

    for i in sorted
      id = i[0]
      country = i[1]
      option = $ '<option/>',
        value: id
      $('#ttv-country').append(option.text(country))

    initInputValues()
    $('#ttv-country').change ->
      refreshResultsCount()

city_id = variables().departure_city or 14
loadCountriesList(city_id)

resize = ->
  noResponsiveness($container)

$(window).resize ->
  resize()


$form = $('.ttv-search-form')
$form.submit (e) ->
  data = getFormData($form)
  data = validateForm(data)

  params = $.param(data)

#  params = $form.serialize()
  console.log 'Параметры поиска(отправка)', params
  catalog_url = $container.data('catalog-url')
  console.log 'URL каталога', catalog_url
  del = require('./router').del
  window.location.href = catalog_url + '#search' + del + params
  e.preventDefault()


refreshResultsCount()
resize()
