addResponsiveClass = ($container) =>
  if $container.width() < 800
    $container.addClass('mobile')
    $container.removeClass('tablet')
    $container.removeClass('pc')
  else
    $container.removeClass('mobile')
    if $container.width() < 1120
      $container.addClass('tablet')
      $container.removeClass('pc')
    else
      $container.addClass('pc')
      $container.removeClass('tablet')

noResponsiveness = ($container) =>
  $container.addClass('pc')
  $container.removeClass('tablet')
  $container.removeClass('mobile')


module.exports = { addResponsiveClass, noResponsiveness }