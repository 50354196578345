#window.$ = jQuery = window.jQuery = $ = require 'jquery'
jQuery = $ = require 'jquery'
_ = require 'underscore'
#require 'owl.carousel'
#require 'slick-carousel'
Swiper = require 'swiper'

router = require './router'

$container = $('.ttv-hottours')
$items = $('.ttv-hottours ul')

catalog_url = $container.data('catalog-url')

if $container.length
  console.log 'Найден элемент виджета горячих туров. Инициализация виджета...'

  $.getJSON '//www.tourtrans.ru/modules/hottours.html?jsoncallback=?', (data) ->

    if not $items.length
      $slides = $ '<div/>',
        class: 'ttv-slides'

      $container.append($slides)

      $items = $ '<ul/>'
      $items.addClass('swiper-wrapper')
#      $items.addClass('owl-carousel') # fix owl awkwardness
      $slides.append($items)

#      $lft = $ '<div/>',
#        class: 'ttv-left'
#      $container.append($lft)
#
#      $rgt = $ '<div/>',
#        class: 'ttv-right'
#      $container.append($rgt)

      $lft = $ '<div/>',
        class: 'swiper-button-prev'
      $container.append($lft)

      $rgt = $ '<div/>',
        class: 'swiper-button-next'
      $container.append($rgt)

      $pagination = $ '<div/>',
        class: 'swiper-pagination'
      $container.append($pagination)

    item_tpl = _.template(require './hottours_item.html')
    for tour in data
#      console.log item_tpl(tour)
#      console.log $items
#      console.log $(item_tpl(tour))
      tour.catalog_url = catalog_url
      tour.del = router.del
      $items.append $(item_tpl(tour))

    swiper = new Swiper('.ttv-slides', {
      slidesPerView: 3,
      spaceBetween: 32,
      breakpoints: {
        720: {
          slidesPerView: 1,
          spaceBetween: 32,
        }
        1200: {
          slidesPerView: 2,
          spaceBetween: 32,
        }
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
    })

#    window.sw = swiper
    swiper.getBreakpoint = (breakpoints) ->
      if !breakpoints
        return undefined
      breakpoint = false
      points = []
      Object.keys(breakpoints).forEach (point) =>
        points.push(point)
      points.sort (a,b) =>
        parseInt(a, 10) - parseInt(b, 10)
      for point in points
#        if point >= window.innerWidth && !breakpoint
        if point >= $container.width() && !breakpoint
          breakpoint = point
      return breakpoint || 'max'
    swiper.update()
#    $('.ttv-slides ul').owlCarousel({
#      responsiveBaseElement: '.ttv-slides',
#      responsiveClass: 'resp',
#      responsive: {
#        0: {
#          items: 1,
#        },
#        600: {
#          items: 2,
#        }
#      }
#    })
#    slider($container.get(0))
